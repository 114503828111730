@charset "utf-8";





// ----------------------------------------
// Vars

$minimum: "only screen and (max-width:500px)";
$mobile: "only screen and (max-width:600px)";
$tablet: "only screen and (max-width:1000px)";

$text-color: #333;
$text-color-sub: #666;

$default-font-size: 1.6em;
$content-max-width: 1000px;
$margin-paragraph: 2em;





// ----------------------------------------
// Utils

@mixin font-size($s:1.0) {
	font-size:($s * 10) + px;
	font-size:$s + rem;
}

@mixin margin-bottom($s:1.0) {
	margin-bottom:($s * 10) + px;
	margin-bottom:$s + rem;
}

@mixin figure($max-width, $margin-bottom:0) {
	display: block;
	margin: 0 auto $margin-bottom;
	width: 100%;
	max-width: $max-width;
}

@mixin preloader($scale) {
	$original-size: 200px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: (-($original-size * $scale / 2)) 0 0 (-($original-size * $scale / 2));
	z-index: 2;
	.indicator {
		position: absolute;
		display: block;
		width: $original-size * $scale;
		height: $original-size * $scale;
	}
}

@mixin hover-fade($alpha:0.3, $duration:0.2s, $easing:ease-out) {
	-webkit-transition: $duration $easing;
	-moz-transition: $duration $easing;
	-o-transition: $duration $easing;
	transition: $duration $easing;
	&:hover {
		cursor: pointer;
		opacity: $alpha;
		filter: alpha(opacity=$alpha);
	}
}
.touchevents {
	*:hover {
		opacity: inherit !important; //iOS8でのhoverバグ回避
	}
}





// ----------------------------------------
// Base

html {
	font-size: 62.5%;
}

body {
	font-family: "Mandali", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-size: $default-font-size;
	line-height: 250%;
	color: $text-color;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

ul {
	list-style: none;
}

a {
	@include hover-fade();
	color: $text-color;
}

strong {
	font-weight: bold;
}

p:not(:first-of-type) {
	margin-top: $margin-paragraph;
}

hr {
	$thickness: 4px;
	width: 50px;
	height: $thickness;
	border-width: $thickness 0px 0px 0px;
	border-style: solid;
	border-color: #333;
	margin: $margin-paragraph auto 10px;
}

figcaption {
	display: block;
	text-align: center;
}

.preloader {
	@include preloader(0.5);
	visibility: hidden;
	z-index: 1;
	@media #{$mobile} {
		@include preloader(0.3);
	}
}

.foreground {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	//height: 100%;
	z-index: 2;
}

.background {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
	img {
		position: absolute;
	}
}

.title {
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;
	.logo {
		margin: 0 auto;
		.image {
			width: 300px * (592 / 500);
			max-width: 60%;
		}
	}
	.description {
		font-weight: normal;
		font-size: 2em;
		line-height: 120%;
		color: #161515;
		.head {
			font-size: 1.5em;
			margin-left: -0.1em;
			color: #e83828;
		}
		@media #{$mobile} {
			font-size: 1em;
		}
	}
}

.navigation {
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	line-height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.7);
	.button {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
		.label {
			margin-bottom: 12px;
			font-size: 1.2em;
			font-weight: bold;
			color: #333;
		}
		.description {
			margin-bottom: 12px;
			font-size: 0.9em;
			color: #999;
		}
		.icon img {
			width: 30px;
			height: 30px;
		}
		a {
			text-decoration: none;
		}
		&:hover {
			@include hover-fade();
		}
	}
}




/* ---------------------------------------- */
/*
#top {
	background: none;
	padding: 0;

	.section_content {
		width: 80%;

		.logo {
			//margin: 0 auto 40px;
			margin: 0 auto;

			.image {
				width: 300px * (592 / 500);
				max-width: 70%;
			}
		}

		.section_title_container {
			margin-bottom: 0;

			.section_title {
				line-height: 150%;
				font-size: 1.8em;
				letter-spacing: 0.05em;
			}
		}
	}
}
*/




/* ---------------------------------------- */
/*
@media #{$minimum} {

	section {
		.section_content {
			.section_title_container {
				.section_title {
					.head {
						font-size: 1.5em;
					}

					font-size: 3.2em;
					line-height: 150%;
				}

				.line {
					width: 110px;
					margin-top: 5px;
				}
			}
		}
	}
}

@media #{$mobile} {

	body {
		font-size: 1.2em;
	}

	section {
		.section_container {
			.section_content {
				width: 90% !important;
			}
		}
	}
}

@media #{$tablet} {

	section {
		.section_content {
			width: 80% !important;
		}
	}

	#preloader {
		@include preloader(0.4);
	}
}
*/